import React, { useState, useEffect } from 'react';
import './HeaderSelect.scss';
import { toast } from 'react-toastify';
import { fetchBodyType, fetchBrands, fetchEngines, fetchFuels, fetchGearBox, fetchGears, fetchHorsepower, fetchModels, fetchYears, findByVehicleIdentificationId, findPartsFromVehicle } from '../../redux/api/vehicleApi';
import { useAppDispatch } from '../../redux/hooks';
import { setSelectedMyVehicle, setSelectedVehicle } from '../../redux/slices/garageSlice';
import { useNavigate } from 'react-router-dom';


interface BrandOrModelOption {
  id: number;
  name: string;
}

interface CombinationOption {
  year: number;
  fuel: string;
  engine: string;
  gear: string;
  gearBox: string;
  horsepower: string;
  bodyType: string;
}

const HeaderSelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [brands, setBrands] = useState<BrandOrModelOption[]>([]);
  const [models, setModels] = useState<BrandOrModelOption[]>([]);

  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [selectedModel, setSelectedModel] = useState<number | null>(null);

  const [combinations, setCombinations] = useState<CombinationOption[]>([]);
  const [selectedCombination, setSelectedCombination] = useState<string>('');

  const [vinNumber, setVinNumber] = useState<string>('');

  // Markaları ilk yükle
  useEffect(() => {
    const loadBrands = async () => {
      try {
        const response = await fetchBrands();
        setBrands(response.map((b: any) => ({ id: b.id, name: b.name })));
      } catch (error) {
        console.error('Markalar yüklenirken hata oluştu:', error);
        toast.error('Markalar yüklenirken hata oluştu');
      }
    };
    loadBrands();
  }, []);

  // Marka değişince modelleri yükle
  useEffect(() => {
    const loadModels = async () => {
      setModels([]);
      setSelectedModel(null);
      setCombinations([]);
      setSelectedCombination('');
      if (selectedBrand) {
        try {
          const response = await fetchModels(selectedBrand);
          setModels(response.map((m: any) => ({ id: m.id, name: m.name })));
        } catch (error) {
          console.error('Modeller yüklenirken hata oluştu:', error);
          toast('Modeller yüklenirken hata oluştu', {type:'error'});
        }
      }
    };
    loadModels();
  }, [selectedBrand]);

  // Model seçildikten sonra kombinasyonları yükle
  // Burada yıl -> yakıt -> motor -> vites -> şanzıman -> beygir gücü -> kasa tipi adımlarını tek tek dolaşıp
  // tüm olası kombinasyonları çıkarıyoruz.
  useEffect(() => {
    const loadAllCombinations = async () => {
      setCombinations([]);
      setSelectedCombination('');
  
      if (selectedBrand && selectedModel) {
        try {
          const years = await fetchYears(selectedBrand, selectedModel);
          let comboList: CombinationOption[] = [];
  
          // Yıllar için paralel işlemleri başlat
          const yearPromises = years.map(async (year:any) => {
            const fuels = await fetchFuels(selectedBrand, selectedModel, year.id);
  
            // Yakıt türleri için paralel işlemleri başlat
            const fuelPromises = fuels.map(async (fuel:any) => {
              const engines = await fetchEngines(selectedBrand, selectedModel, year.id, fuel.id as string);
  
              // Motorlar için paralel işlemleri başlat
              const enginePromises = engines.map(async (engine:any) => {
                const gears = await fetchGears(selectedBrand, selectedModel, year.id, fuel.id as string, engine.id as string);
  
                // Vites türleri için paralel işlemleri başlat
                const gearPromises = gears.map(async (gear:any) => {
                  const gearBoxes = await fetchGearBox(selectedBrand, selectedModel, year.id, fuel.id as string, engine.id as string, gear.id as string);
  
                  // Şanzıman türleri için paralel işlemleri başlat
                  const gearBoxPromises = gearBoxes.map(async (gearBox:any) => {
                    const horsepowers = await fetchHorsepower(selectedBrand, selectedModel, year.id, fuel.id as string, engine.id as string, gear.id as string, gearBox.id as string);
  
                    // Beygir gücü için paralel işlemleri başlat
                    const horsepowerPromises = horsepowers.map(async (horsepower:any) => {
                      const bodyTypes = await fetchBodyType(selectedBrand, selectedModel, year.id, fuel.id as string, engine.id as string, gear.id as string, gearBox.id as string, horsepower.id as string);
  
                      // Kombinasyonları ekle
                      bodyTypes.forEach((bodyType:any) => {
                        comboList.push({
                          year: year.id as number,
                          fuel: fuel.id as string,
                          engine: engine.id as string,
                          gear: gear.id as string,
                          gearBox: gearBox.id as string,
                          horsepower: horsepower.id as string,
                          bodyType: bodyType.id as string,
                        });
                      });
                    });
  
                    await Promise.all(horsepowerPromises);
                  });
  
                  await Promise.all(gearBoxPromises);
                });
  
                await Promise.all(gearPromises);
              });
  
              await Promise.all(enginePromises);
            });
  
            await Promise.all(fuelPromises);
          });
  
          await Promise.all(yearPromises);
  
          setCombinations(comboList);
        } catch (error) {
          console.error('Kombinasyonlar yüklenirken hata oluştu:', error);
          toast('Motor seçenekleri yüklenirken hata oluştu', { type: 'error' });
        }
      }
    };
  
    loadAllCombinations();
  }, [selectedBrand, selectedModel]);

  const handleSearch = async () => {
    if (vinNumber) {
      try {
        const response = await findByVehicleIdentificationId(vinNumber);
        if (response?.fullMatch) {
          dispatch(setSelectedVehicle(response.vehicle));
          navigate("/vehicle-product");
        }
      } catch (error) {
        console.error('VIN ile araç bulunamadı:', error);
        toast('VIN ile araç bulunamadı', {type:'warning'});
      }
    } else if (selectedCombination) {
      // Seçilen kombinasyonu parse et
      const combo = combinations.find(c => 
        `${c.year}-${c.fuel}-${c.engine}-${c.gear}-${c.gearBox}-${c.horsepower}-${c.bodyType}` === selectedCombination
      );

      if (!combo) {
        toast('Lütfen geçerli bir seçenek seçiniz', {type:'warning'});
        return;
      }

      try {
        const vehicle = await findPartsFromVehicle(
          selectedBrand!,
          selectedModel!,
          combo.year,
          combo.fuel,
          combo.engine,
          combo.gear,
          combo.gearBox,
          combo.horsepower,
          combo.bodyType
        );
        dispatch(setSelectedVehicle(vehicle));
        navigate("/vehicle-product");
      } catch (error) {
        console.error('Araç bulunamadı:', error);
        toast('Araç bulunamadı', {type:'error'});
      }
    } else {
      toast('Lütfen marka, model seçin ve bir kombinasyon veya VIN giriniz', {type: 'warning'});
    }
  };

  return (
    <div className="header-select">
      <div className="row auto-container">
        <div className="col-lg-7 col-xxl-8 mb-3 mb-lg-0">
          <div className="header-select__choosse">
            <span className="title">Aracınızın parçalarını bulun</span>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="header-select__choosse-wrap">
                {/* Marka seçici */}
                <div className="selector">
                  <select
                    value={selectedBrand || ''}
                    onChange={(e) => {
                      const val = e.target.value;
                      setSelectedBrand(val ? Number(val) : null);
                    }}
                  >
                    <option value="">Marka seçiniz</option>
                    {brands.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Model seçici */}
                <div className="selector">
                  <select
                    value={selectedModel || ''}
                    onChange={(e) => {
                      const val = e.target.value;
                      setSelectedModel(val ? Number(val) : null);
                    }}
                    disabled={!selectedBrand}
                  >
                    <option value="">Model seçiniz</option>
                    {models.map((model) => (
                      <option key={model.id} value={model.id}>
                        {model.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Tek dropdown (Kalan tüm seçimler) */}
                <div className="selector">
                  <select
                    value={selectedCombination}
                    onChange={(e) => setSelectedCombination(e.target.value)}
                    disabled={!selectedModel || combinations.length === 0}
                  >
                    <option value="">Seçenekleri seçiniz</option>
                    {combinations.map((combo, index) => {
                      const label = `${combo.year}-${combo.fuel}-${combo.engine}-${combo.gear}-${combo.gearBox}-${combo.horsepower}-${combo.bodyType}`;
                      return (
                        <option key={index} value={label}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <button className="button" type="button" onClick={handleSearch}>
                  Ara
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-5 col-xxl-4">
          <div className="header-select__number">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="title">
                <span>VIN aşağıya girin</span>
              </div>

              <div className="header-select__number-wrap">
                <input
                  type="text"
                  placeholder="VIN numaranız"
                  value={vinNumber}
                  onChange={(e) => setVinNumber(e.target.value)}
                  maxLength={17}
                />
                <button className="button" onClick={handleSearch}>
                  Ara
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSelect;