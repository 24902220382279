import React from 'react'
import BrandBlock from '../../component/brandBlock/BrandBlock'
import HomeBlogPage from './HomeBlogPage'
import HomeCategoryBlock from './HomeCategoryBlock'
import HeaderSelect from '../../component/banner/HeaderSelect'

export default function Home() {
  
  return (
    <>
      <HeaderSelect/>
      <HomeCategoryBlock/>
      <BrandBlock />
      <HomeBlogPage />
    </>
  )
}
