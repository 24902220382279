// SalesContract.tsx
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice"; // Kullanıcı bilgisini almak için
import { CartItem } from "../cartBody/CartBody";

interface SalesContractProps {
  orderDetails: {
    carts: CartItem[];
    totalAmount: number;
    paymentMethod: string;
    shippingAddress: string;
    billingAddress: string;
    shippingFee: number;
  };
}

const SalesContract: React.FC<SalesContractProps> = ({ orderDetails }) => {
  const user = useSelector(selectUser).user || {
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    avatar: "",
    isEmailSubscribed: "",
    isSmsSubscribed: "",
  };
  const [ipAddress, setIpAddress] = useState<string>("");
  const currentDate = new Date().toLocaleDateString("tr-TR");

  useEffect(() => {
    // IP adresini almak için bir API çağrısı
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => setIpAddress(data.ip))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="sales-contract">
      <h1>MESAFELİ SATIŞ SÖZLEŞMESİ</h1>
      <h2>MADDE 1- TARAFLAR</h2>

      <h3>1.1. SATICI:</h3>
      <p>Ünvanı: sonparca.com</p>
      <p>Adresi: ...</p>
      <p>Telefon: ...</p>
      <p>Mersis Numarası: ...</p>

      <h3>1.2. ALICI("TÜKETİCİ"):</h3>
      <p>
        Adı/Soyadı/Ünvanı: {user.firstName} {user.lastName}
      </p>
      <p>Adresi: {orderDetails.billingAddress}</p>
      <p>Telefon: {user.phone}</p>
      <p>Email: {user.email}</p>

      <h2>MADDE 2- KONU</h2>
      <p>
        İşbu sözleşmenin konusu, TÜKETİCİ'nin sonparca.com internet sitesinden
        elektronik ortamda siparişini yaptığı aşağıda nitelikleri ve satış
        fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak 6502 sayılı
        Tüketicinin Korunması Hakkındaki Kanun hükümleri gereğince tarafların
        hak ve yükümlülüklerinin saptanmasıdır.
      </p>

      <h2>
        MADDE 3- SÖZLEŞME KONUSU ÜRÜN, ÖDEME VE TESLİMATA İLİŞKİN BİLGİLER
      </h2>
      <h3>
        3.1- Sözleşme konusu mal veya hizmetin adı, adeti, KDV dahil satış
        fiyatı, ödeme şekli ve temel nitelikleri
      </h3>
      <table>
        <thead>
          <tr>
            <th>Ürün Adı ve Temel Nitelikleri</th>
            <th>Adet</th>
            <th>Satış Bedeli (KDV dahil TL)</th>
          </tr>
        </thead>
        <tbody>
          {orderDetails.carts.map((product, index) => (
            <tr key={index}>
              <td>{product.productName}</td>
              <td>{product.quantity}</td>
              <td>{product.price.toFixed(2)} TL</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        <strong>Toplam Tutar: {orderDetails.totalAmount.toFixed(2)} TL</strong>
      </p>

      <h3>3.2- Ödeme Şekli: {orderDetails.paymentMethod}</h3>
      {/* Sözleşmenin devamını aynı şekilde ekleyebilirsiniz */}

      <h2>MADDE 4- CAYMA HAKKI</h2>
      <p>
        TÜKETİCİ, SATICI ile imzaladığı işbu Mesafeli Satış Sözleşmesi'nden 14
        (ondört) gün içinde herhangi bir gerekçe göstermeksizin ve cezai şart
        ödemeksizin cayma hakkına sahiptirCayma hakkı süresi, hizmet ifasına
        ilişkin sözleşmelerde sözleşmenin kurulduğu gün; mal teslimine ilişkin
        sözleşmelerde ise TÜKETİCİ'nin veya TÜKETİCİ tarafından belirlenen
        üçüncü kişinin malı teslim aldığı gün başlar. Ancak TÜKETİCİ,
        sözleşmenin kurulmasından malın teslimine kadar olan süre içinde de
        cayma hakkını kullanabilir. Cayma hakkı süresinin belirlenmesinde;
      </p>
      <p>
        a) Tek sipariş konusu olup ayrı ayrı teslim edilen mallarda,
        TÜKETİCİ'nin veya TÜKETİCİ tarafından belirlenen üçüncü kişinin son malı
        teslim aldığı gün, b) Birden fazla parçadan oluşan mallarda,
        TÜKETİCİ'nin veya TÜKETİCİ tarafından belirlenen üçüncü kişinin son
        parçayı teslim aldığı gün, c) Belirli bir süre boyunca malın düzenli
        tesliminin yapıldığı sözleşmelerde, TÜKETİCİ'nin veya TÜKETİCİ
        tarafından belirlenen üçüncü kişinin ilk malı teslim aldığı gün esas
        alınır. Cayma bildiriminizi cayma hakkı süresi dolmadan
        www.sonparca.com 'da yer alan kişisel üyelik sayfanızdaki kolay
        iade seçeneği üzerinden gerçekleştirebilirsiniz. Cayma hakkınız
        kapsamında öngörülen taşıyıcı sipariş edilen ürünün tarafınıza teslim
        edildiği kargo firması olup, www.sonparca.com 'da yer alan kişisel
        üyelik sayfanızdaki kolay iade seçeneğinde geri gönderime ilişkin
        detaylar açıklanmıştır. Tüketici aşağıdaki sözleşmelerde cayma hakkını
        kullanamaz: a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak
        değişen ve SATICI veya sağlayıcının kontrolünde olmayan mal veya
        hizmetlere ilişkin sözleşmeler. b) Tüketicinin istekleri veya kişisel
        ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler. c)
        Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların
        teslimine ilişkin sözleşmeler. ç) Tesliminden sonra ambalaj, bant,
        mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi
        sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin
        sözleşmeler. d) Tesliminden sonra başka ürünlerle karışan ve doğası
        gereği ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler. e)
        Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
        unsurları açılmış olması halinde maddi ortamda sunulan kitap, dijital
        içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler. f) Abonelik
        sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli
        yayınların teslimine ilişkin sözleşmeler. g) Belirli bir tarihte veya
        dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama,
        yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş
        zamanın değerlendirilmesine ilişkin sözleşmeler. ğ) Elektronik ortamda
        anında ifa edilen hizmetler veya TÜKETİCİ'ye anında teslim edilen
        gayrimaddi mallara ilişkin sözleşmeler. h) Cayma hakkı süresi sona
        ermeden önce, TÜKETİCİ'nin onayı ile ifasına başlanan hizmetlere ilişkin
        sözleşmeler. ı) TÜKETİCİ siparişine istinaden yurt içinden veya dışından
        ithal / temin edilen ürünlerde cayma hakkı kullanılamaz.
      </p>

      <h2>MADDE 5- GENEL HÜKÜMLER</h2>
      <p>
        5.1- TÜKETİCİ, www.sonparca.com internet sitesinde sözleşme konusu
        ürüne ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik
        ortamda gerekli teyidi verdiğini beyan eder. 5.2- Ürün sözleşme
        tarihinden itibaren en geç 30 gün içerisinde teslim edilecektir.
        Ürününün teslim edilmesi anına kadar tüm sorumluluk SATICI'ya aittir.
        5.3- Sözleşme konusu ürün, TÜKETİCİ'dan başka bir kişi/kuruluşa teslim
        edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul
        etmemesinden SATICI sorumlu tutulamaz. 5.4- SATICI, sözleşme konusu
        ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa
        garanti belgeleri ve kullanım kılavuzları ile teslim edilmesinden
        sorumludur. 5.5- Sözleşme konusu ürünün teslimatı için işbu sözleşmenin
        bedelinin TÜKETİCİ'nin tercih ettiği ödeme şekli ile ödenmiş olması
        şarttır. Herhangi bir nedenle ürün bedeli ödenmez veya banka
        kayıtlarında iptal edilir ise, SATICI ürünün teslimi yükümlülüğünden
        kurtulmuş kabul edilir. 5.6- Ürünün tesliminden sonra TÜKETİCİ'ya ait
        kredi kartının TÜKETİCİ'nin kusurundan kaynaklanmayan bir şekilde
        yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni
        ile ilgili banka veya finans kuruluşun ürün bedelini SATICI'ya ödememesi
        halinde, TÜKETİCİ'nin kendisine teslim edilmiş olması kaydıyla ürünün
        SATICI'ya gönderilmesi zorunludur. 5.7- Garanti belgesi ile satılan
        ürünlerden olan veya olmayan ürünlerin ayıplı (arızalı, bozuk vb.)
        halinde veya garanti kapsamında ve şartları dahilinde arızalanması veya
        bozulması halinde gerekli onarımın yetkili servise yaptırılması için
        sözkonusu ürünler SATICI'ya gönderilebilir, bu takdirde kargo giderleri
        SATICI tarafından karşılanacaktır. 5.8- 385 sayılı vergi usul kanunu
        genel tebliği uyarınca iade işlemlerinin yapılabilmesi için tarafınıza
        göndermiş olduğumuz iade bölümü bulunan faturada ilgili bölümlerin
        eksiksiz olarak doldurulması ve imzalandıktan sonra tarafımıza ürün ile
        birlikte geri gönderilmesi gerekmektedir. Ürün iade ve değişimlerde
        kargo ücreti TÜKETİCİ tarafından karşılanacaktır. 5.9- TÜKETİCİ,
        www.sonparca.com internet sitesinden sipariş verdiği ürünlerde
        siparişe özel olarak yurtiçinden veya yurtdışından tedarik edilmesi
        durumunda aşağıdaki hususları kabul ettiğini beyan eder. a) Tedarik
        süresi 3-60 iş günü olup bu süre içinde TÜKETİCİ siparişi iptal edemez.
        b) Tedarik edilen ürünlerde TÜKETİCİ cayma hakkını kullanamaz. c)
        Tedarik edilen ürünler döviz kuru veya fiyat değişiminden dolayı
        oluşabilecek fiyat farkını TÜKETİCİ ödemeyi kabul eder.
      </p>

      <h2>MADDE 6- UYUŞMAZLIK VE YETKİLİ MAHKEME</h2>
      <p>
        İşbu sözleşme ile ilgili çıkacak ihtilaflarda; Türk Mahkemeleri yetkili
        olup; uygulanacak hukuk Türk Hukuku'dur. Türkiye Cumhuriyeti sınırları
        içerisinde geçerli olmak üzere her yıl Gümrük ve ticaret Bakanlığı
        tarafından ilan edilen değere kadar olan ihtilaflar için TÜKETİCİ
        işleminin yapıldığı veya TÜKETİCİ ikametgahının bulunduğu yerdeki İl
        veya İlçe Tüketici Hakem Heyetleri,söz konusu değerin üzerindeki
        ihtilaflarda ise TÜKETİCİ işleminin yapıldığı veya TÜKETİCİ
        ikametgahının bulunduğu yerdeki Tüketici Mahkemeleri Yetkili olacaktır.
        Siparişin gerçekleşmesi durumunda TÜKETİCİ işbu sözleşmenin tüm
        koşullarını kabul etmiş sayılır.
      </p>
      <p>SATICI : sonparca.com</p>

      <p>
        ALICI("TÜKETİCİ") :{user.firstName} {user.lastName}
      </p>
      <p>Tarih: {currentDate}</p>
      <p>IP: {ipAddress}</p>
    </div>
  );
};

export default SalesContract;
