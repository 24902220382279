import { fetchService, serverUrl } from "./fetchService";

// Define the CardPaymentRequest interface
export interface CardPaymentRequest {
  cardHolderName?: string;
  cardNumber?: string;
  expiryMonth: number;
  expiryYear: number;
  cvc: number;
  installment: number;
  saveCard: boolean;
}

// Get installment options for a specific product
export async function getInstallmentOptions(productId: number) {
  const path = `/Payment/getInstallmentOptions?productId=${productId}`;
  return await fetchService({
    url: serverUrl + path,
    method: "GET",
    throwErrors: false,
  });
}

// Get installment options for the current cart using a card number
export async function getCartInstallmentOptions(cardNumber: string) {
  const path = `/Payment/getCartInstallmentOptions?cardNumber=${cardNumber}`;
  return await fetchService({
    url: serverUrl + path,
    method: "GET",
    throwErrors: false,
  });
}

// Create a non-3D secure payment
export async function createNon3DSecurePayment(paymentRequest: CardPaymentRequest) {
  const path = "/Payment/createNon3DSecurePayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    body: paymentRequest,
    throwErrors: false,
  });
}

// Create a 3D secure payment
export async function create3DSecurePayment(paymentRequest: CardPaymentRequest) {
  const path = "/Payment/create3DSecurePayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    body: paymentRequest,
    throwErrors: false,
  });
}

// Handle the result of a 3D secure payment
export async function secure3DPaymentResult(paymentId: string) {
  const path = `/Payment/secure3DPaymentResult?paymentId=${paymentId}`;
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

// Create an Iyzico payment
export async function createIyzicoPayment() {
  const path = "/Payment/createIyzicoPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

// Handle the result of an Iyzico payment
export async function iyzicoPaymentResult() {
  const path = "/Payment/iyzicoPaymentResult";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

// Check the result of a payment by payment ID
export async function checkPaymentResult(paymentId: number) {
  const path = `/Payment/checkPaymentResult?paymentId=${paymentId}`;
  return await fetchService({
    url: serverUrl + path,
    method: "GET",
    throwErrors: false,
  });
}

// Create a payment for "On Door Payment"
export async function createOnDoorPayment() {
  const path = "/Payment/createMoneyTransferPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}

// Create a payment for "EFT/Havale Payment"
export async function createEftHavalePayment() {
  const path = "/Payment/createMoneyTransferPayment";
  return await fetchService({
    url: serverUrl + path,
    method: "POST",
    throwErrors: false,
  });
}