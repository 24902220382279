import React from 'react';
import './Footer.scss';

export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="footer-container auto-container">
        <div className="footer-column">
          <ul>
            <li className="footer-icon">
                <img src="/assets/images/son-parca-icon.png" alt="" />
            </li>
            <li>
              <a href="mailto:destek@sonparca.com.tr">destek@sonparca.com.tr</a>
            </li>
            <li className="social-icons">
              <a href="#"><i className="fab fa-facebook-f"></i></a>
              <a href="#"><i className="fab fa-youtube"></i></a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Müşteri Hizmetleri</h4>
          <p>0850 840 34 00</p>
          <p>Çalışma Saatleri: Hafta içi 10:00 - 17:00, Cumartesi 10:00 - 15:00</p>
        </div>
        <div className="footer-column">
          <h4>Hakkımızda</h4>
          <ul>
            <li><a href="#">Hesap Bilgileri</a></li>
            <li><a href="#">Künye</a></li>
            <li><a href="#">Hakkımızda</a></li>
            <li><a href="#">İletişim</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Üyelik İşlemleri</h4>
          <ul>
            <li><a href="#">Üye Ol/Giriş</a></li>
            <li><a href="#">Sipariş Takip</a></li>
            <li><a href="#">Kampanyalar</a></li>
            <li><a href="#">Gizlilik Politikası</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Sipariş İşlemleri</h4>
          <ul>
            <li><a href="#">Toplu Parça Teklifi</a></li>
            <li><a href="#">Yardım</a></li>
            <li><a href="#">Kolay İade</a></li>
            <li><a href="#">Kargo İşlemleri</a></li>
          </ul>
        </div>
      </div>
      {/* New copyright and card section */}
      <div className="bottom-bar">
        <div className="copyright-text">
          Copyright © 2023 <span>Son Parça</span>. All rights reserved.
        </div>
        <div className="copyright-card">
          <a href="#"><img src="/assets/images/icons/card-axess.png" alt="card"/></a>
          <a href="#"><img src="/assets/images/icons/card-bonus.png" alt="card"/></a>
          <a href="#"><img src="/assets/images/icons/card-finans.png" alt="card"/></a>
          <a href="#"><img src="/assets/images/icons/card-maximum.png" alt="card"/></a>
          <a href="#"><img src="/assets/images/icons/card-paraf.png" alt="card"/></a>
          <a href="#"><img src="/assets/images/icons/card-word.png" alt="card"/></a>
          <a href="#"><img src="/assets/images/icons/card-iyzico.png" alt="card"/></a>
        </div>
      </div>
    </footer>
  );
}
