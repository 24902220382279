import { fetchService, serverUrl } from "./fetchService";

export interface ICartItem {
  productId: number;
  quantity: number;
  vehicleId: number;
  id?:number;
}

// Function to fetch cart items
export async function fetchCartItems() {
  const path = "/Cart/getCartList";
  return await fetchService({
    url: serverUrl + path,
    method: "GET",
    catchAuthError: false,
  });
}

// Function to add an item to the cart
export async function addItemToCart(item: ICartItem) {
  const path = `/Cart/addItemToCart`;
  await fetchService({
    url: serverUrl + path,
    body: item,
    method: "POST",
    catchAuthError: false,
  });
  // Fetch the updated cart after adding the item
  const cartResult = await fetchCartItems();
  return cartResult;
}

// Function to remove an item from the cart
export async function removeItemFromCart(productId: number) {
  const path = `/Cart/removeItemFromCart?productId=${productId}`;
  return await fetchService({ url: serverUrl + path, method: "DELETE" });
}

// Function to clear the entire cart
export async function clearCart() {
  const path = "/Cart/clearCart";
  return await fetchService({
    url: serverUrl + path,
    method: "DELETE",
    catchAuthError: false,
  });
}

export async function updateCartItemQuantity(item: ICartItem) {
  const path = `/Cart/updateItemInCart`;
  return await fetchService({
    url: serverUrl + path,
    body: {
      id: item.id,
      quantity: item.quantity,
      vehicleId: item.vehicleId,
    },
    method: "PATCH",
  });
}
