import React, { useEffect, useMemo, useState } from "react";
import "./ProductGrid.scss";
import { useSelector } from "react-redux";
import {
  clearSearchQuery,
  clearSelectedCategory,
  selectSearchQuery,
  selectSelectedCategory,
  setSelectedCategory,
} from "../../redux/slices/productSlice";
import { Link } from "react-router-dom";
import {
  IBrand,
  ICategory,
  IProduct,
  IProductResponse,
} from "../../interfaces/product";
import ProductBlock from "../weekDeal/ProductBlock";
import { useAppDispatch } from "../../redux/hooks";
import { divideArrayIntoGroups } from "../weekDeal/ProductUtil";
import { IMyVehicle, IVehicle } from "../../interfaces/garage";
import SelectedVehicle from "../selectedVehicle/SelectedVehicle";
import { fetchProducts } from "../../redux/api/productApi";
import {
  selectVehicles,
  selectSelectedVehicle,
  selectSelectedMyVehicle,
  setSelectedVehicle,
  clearSelectedMyVehicle,
  setSelectedMyVehicle,
  clearSelectedVehicle,
  fetchVehiclesAsync,
} from "../../redux/slices/garageSlice";
import { selectUser } from "../../redux/slices/userSlice";
import MyVehicleSelect from "../../pages/maintainRobot/MyVehicleSelect";
import BannerVehicleSelect from "../banner/BannerVehicleSelect";
import Tag from "../tag/Tag";

interface ProductGridProps {
  productList?: IProductResponse;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  setPage?: (page: number) => void;
  categoryId?: number;
  pagingActive?: boolean;
  categoryInactive?: boolean;
  addCart?: (product: IProduct) => void;
  groupedSize?: number;
  setSelectedBrand?: (brand?: IBrand) => void;
  setOrderValue?: (orderValue: string) => void;
  orderValue?: string;
  setAscDesc?: (ascDesc: boolean) => void;
  ascDesc?: boolean;
  myVehicle?: IMyVehicle;
  onVehicleClose?: () => void;
}

export default function ProductGrid(props: ProductGridProps) {
  const dispatch = useAppDispatch();
  const selectedCategory = useSelector(selectSelectedCategory);
  const categoryId =
    selectedCategory?.id !== undefined
      ? selectedCategory?.id + ""
      : props.categoryId !== undefined
        ? props.categoryId + ""
        : undefined;
  const user = useSelector(selectUser);
  const isUserAuth = !!user?.user;
  const vehicles = useSelector(selectVehicles);
  const [productListResponse, setProductListResponse] =
    useState<IProductResponse>(props.productList || ({} as IProductResponse));
  const selectedVehicle = useSelector(selectSelectedVehicle);
  const selectedMyVehicle = useSelector(selectSelectedMyVehicle);
  const [selectedBrand, setSelectedBrand] = useState<IBrand | undefined>();
  const searchQuery = useSelector(selectSearchQuery);
  const [timer, setTimer] = React.useState<any>(null);
  const [allCategoriesOpened, setAllCategoriesOpened] = React.useState(false);

  const lastSelectedVehicle = useMemo(
    () =>
      selectedVehicle
        ? ({ vehicle: selectedVehicle as IVehicle } as IMyVehicle)
        : selectedMyVehicle?.vehicle?.id
          ? selectedMyVehicle
          : undefined,
    [selectedVehicle, selectedMyVehicle],
  );

  const setVehicle = (vehicle: IVehicle) => {
    dispatch(setSelectedVehicle(vehicle));
    dispatch(clearSelectedMyVehicle());
  };
  const setMyVehicle = (vehicle: IMyVehicle) => {
    dispatch(setSelectedMyVehicle(vehicle));
    dispatch(clearSelectedVehicle());
  };

  const [page, setPage] = React.useState(1);

  useEffect(() => {
    if (props.productList) {
      setProductListResponse(props.productList);
    }
  }, [props.productList]);

  useEffect(() => {
    if (props.productList) {
      return;
    }
    timer && clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        fetchProducts({
          sortAsc: false,
          limit: 40,
          page: page,
          slug: "",
          categoryId: categoryId,
          vehicleId: lastSelectedVehicle?.vehicle?.id
            ? lastSelectedVehicle?.vehicle?.id + ""
            : undefined,
          brandId: selectedBrand?.id ? selectedBrand?.id + "" : undefined,
          searchQuery: searchQuery,
          includeCategories: true,
          includeBrands: true,
        }).then((response) => {
          if (!response) {
            return;
          }
          setProductListResponse(response);
          setPage(response.currentPage);
        });
      }, 500),
    );
  }, [
    categoryId,
    lastSelectedVehicle,
    lastSelectedVehicle?.vehicle?.id,
    selectedBrand?.id,
    page,
    searchQuery,
  ]);

  useEffect(() => {
    if (isUserAuth) {
      dispatch(fetchVehiclesAsync());
    }
  }, []);

  const [categorySearch, setCategorySearch] = React.useState("");
  const { groupedSize = 4 } = props;
  const categories =
    productListResponse?.categories?.filter((c) =>
      c.name.toLowerCase().includes(categorySearch.toLowerCase()),
    ) || [];
  const brands = productListResponse?.brands || [];
  const groupedProducts = divideArrayIntoGroups(
    productListResponse.items,
    groupedSize,
  );
  const handleCategoryChange = (category: ICategory) => {
    setAllCategoriesOpened(false);
    dispatch(setSelectedCategory(category));
  };
  const [openedCategoryList, setOpenedCategoryList] = React.useState<number[]>(
    [],
  );

  const getOpenedCategoryList = (category: ICategory) => {
    const categoryList = [];
    categoryList.push(...categories);
    categories.forEach((c) => {
      categoryList.push(...c.subCategories);
      c.subCategories.forEach((sc) => {
        if (sc.subCategories) {
          categoryList.push(...sc.subCategories);
        }
      });
    });
    const openedCategoryList = [];
    let currCategory = category;
    while (currCategory) {
      openedCategoryList.push(currCategory.id);
      currCategory = categoryList.find(
        (c) => c.id === currCategory.parentId,
      ) as ICategory;
    }
    return openedCategoryList;
  };

  useEffect(() => {
    if (selectedCategory) {
      setOpenedCategoryList(getOpenedCategoryList(selectedCategory));
    }
  }, []);

  const handleBrandChange = (brand: IBrand) => {
    props.setSelectedBrand && props.setSelectedBrand(brand);
  };
  const onVehicleClose = () => {
    dispatch(clearSelectedVehicle());
    dispatch(clearSelectedMyVehicle());
    props.onVehicleClose && props.onVehicleClose();
  };

  return (
    <section className="shop-page-section p_relative mt-2">
      <div className="auto-container">
        <div className="row clearfix">
          {props.categoryInactive ? (
            <></>
          ) : (
            <div className="col-lg-3 col-md-12 cols-sm-12 sidebar-side">
              <div className="shop-sidebar">
                {!(lastSelectedVehicle && lastSelectedVehicle?.vehicle?.id) && (
                  <>
                    <div className="mb-3">
                      {isUserAuth && (
                        <>
                          <h5>Garaja Göre Arama</h5>
                          <MyVehicleSelect
                            vehicleList={vehicles}
                            selectedVehicle={selectedMyVehicle}
                            setSelectedVehicle={setMyVehicle}
                          />
                        </>
                      )}
                    </div>
                    <div className="mb-3">
                      <h5>Aracınıza Göre Arama</h5>
                      <BannerVehicleSelect
                        vehicle={selectedVehicle}
                        onSubmit={(vehicle, year) => {
                          vehicle.year = year;
                          setVehicle(vehicle);
                        }}
                      />
                    </div>
                  </>
                )}
                {lastSelectedVehicle && lastSelectedVehicle?.vehicle?.id && (
                  <div className="mb-3">
                    <SelectedVehicle
                      myVehicle={lastSelectedVehicle}
                      onClose={onVehicleClose}
                    />
                  </div>
                )}

                <div className=" mb-2">
                  <div className="widget-title p_relative d_block d-flex">
                    <h4
                      className="p_relative d_block fs_20 lh_30"
                      onClick={() => dispatch(clearSelectedCategory())}
                    >
                      Kategoriler (Tümü)
                    </h4>
                    <button
                      onClick={() =>
                        setAllCategoriesOpened(!allCategoriesOpened)
                      }
                      className="p_relative d_block fs_15 color_black pl-2"
                    >
                      <i
                        className={
                          "fas fa-chevron-" +
                          (allCategoriesOpened ? "up" : "down")
                        }
                      ></i>
                    </button>
                  </div>
                  {allCategoriesOpened && (
                    <div className="widget-content">
                      <div className="search-widget sidebar-widget p_relative d_block mt-2 mb-2">
                        <form className="search-form">
                          <div className="form-group p_relative m_0">
                            <input
                              type="search"
                              name="search-field"
                              placeholder="Kategorileri Filtrele"
                              value={categorySearch}
                              onChange={(e) =>
                                setCategorySearch(e.target.value)
                              }
                            />
                            <button>
                              <i className="far fa-search"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                      <ul className="category-list clearfix">
                        {categories.map((mc) => (
                          <li className="p_relative d_block mb_11" key={mc.url}>
                            <div className="d_flex">
                              <button
                                onClick={() => handleCategoryChange(mc)}
                                className={
                                  "p_relative d_iblock fs_15 font_family_inter color_black pl_20" +
                                  (selectedCategory?.id === mc.id
                                    ? " active"
                                    : "")
                                }
                              >
                                {`${mc.name} (${mc.productCount})`}
                              </button>
                              <button
                                onClick={() => {
                                  if (openedCategoryList.includes(mc.id)) {
                                    setOpenedCategoryList(
                                      openedCategoryList.filter(
                                        (c) => c !== mc.id,
                                      ),
                                    );
                                  } else {
                                    setOpenedCategoryList([mc.id]);
                                  }
                                }}
                                className="p_relative d_iblock fs_15 color_black ml-2"
                              >
                                <i
                                  className={
                                    "fas fa-chevron-" +
                                    (openedCategoryList.includes(mc.id)
                                      ? "up"
                                      : "down")
                                  }
                                ></i>
                              </button>
                            </div>
                            {openedCategoryList.includes(mc.id) && (
                              <ul className="ml-4">
                                {mc.subCategories
                                  ?.filter((c) => c.parentId === mc.id)
                                  ?.map((c) => (
                                    <li key={c.url}>
                                      <div className="d_flex">
                                        <button
                                          onClick={() =>
                                            handleCategoryChange(c)
                                          }
                                          className={
                                            "p_relative d_block fs_13 font_family_poppins color_black pl-2" +
                                            (selectedCategory?.url === c.url
                                              ? " active"
                                              : "")
                                          }
                                        >
                                          {`${c.name} (${c.productCount})`}
                                        </button>
                                        <button
                                          onClick={() => {
                                            if (
                                              openedCategoryList.includes(c.id)
                                            ) {
                                              setOpenedCategoryList(
                                                openedCategoryList.filter(
                                                  (cc) => cc !== c.id,
                                                ),
                                              );
                                            } else {
                                              setOpenedCategoryList([
                                                ...openedCategoryList,
                                                c.id,
                                              ]);
                                            }
                                          }}
                                          className="p_relative d_iblock fs_15 color_black ml-2"
                                        >
                                          <i
                                            className={
                                              "fas fa-chevron-" +
                                              (openedCategoryList.includes(c.id)
                                                ? "up"
                                                : "down")
                                            }
                                          ></i>
                                        </button>
                                      </div>
                                      {openedCategoryList.includes(c.id) && (
                                        <ul className="ml-4">
                                          {c.subCategories
                                            .filter(
                                              (cc) => cc.parentId === c.id,
                                            )
                                            .map((cc) => (
                                              <li
                                                key={cc.url}
                                                className="d_flex"
                                              >
                                                <button
                                                  onClick={() =>
                                                    handleCategoryChange(cc)
                                                  }
                                                  className={
                                                    "p_relative d_block fs_13 font_family_poppins color_black pl-2" +
                                                    (selectedCategory?.url ===
                                                    cc.url
                                                      ? " active"
                                                      : "")
                                                  }
                                                >
                                                  {`${cc.name} (${cc.productCount})`}
                                                </button>
                                              </li>
                                            ))}
                                        </ul>
                                      )}
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className={`${props.categoryInactive ? "col-lg-12 mb-5" : "col-lg-9"} col-md-12 cols-sm-12 content-side`}
          >
            <div className="our-shop">
              <div className="item-shorting p_relative d_block mb_20 clearfix">
                <div className="left-column pull-left clearfix d-flex align-items-center">
                  {productListResponse && (
                    <div className="text float_left">
                      <p className="fs_16 font_family_poppins">
                        <span className="color_black">
                          {productListResponse?.totalCount !== undefined
                            ? productListResponse.totalCount
                            : 0}
                        </span>{" "}
                        Ürün Listelendi
                      </p>
                    </div>
                  )}
                  {selectedCategory && (
                    <Tag
                      tag={`Kategori: ${selectedCategory?.name}`}
                      onDelete={() => dispatch(clearSelectedCategory())}
                    />
                  )}
                  {selectedBrand && (
                    <Tag
                      tag={`Marka: ${selectedBrand?.name}`}
                      onDelete={() => setSelectedBrand(undefined)}
                    />
                  )}
                  {searchQuery && (
                    <Tag
                      tag={`Arama: ${searchQuery}`}
                      onDelete={() => dispatch(clearSearchQuery())}
                    />
                  )}
                </div>

                {props.orderValue !== undefined &&
                  props.setOrderValue !== undefined &&
                  props.setAscDesc !== undefined &&
                  props.setAscDesc !== undefined && (
                    <div className="right-column pull-right clearfix">
                      <div className="short-box clearfix">
                        <div className="select-box">
                          <select
                            className="wide nice-select"
                            onChange={(e) =>
                              props.setOrderValue !== undefined &&
                              props.setOrderValue(e.target.value)
                            }
                          >
                            <option data-display="Sıralama">Sıralama</option>
                            <option
                              value="Id"
                              selected={"Id" === props.orderValue}
                            >
                              Ürün No
                            </option>
                            <option
                              value="Product"
                              selected={"Product" === props.orderValue}
                            >
                              Ada Göre
                            </option>
                            <option
                              value="Price"
                              selected={"Price" === props.orderValue}
                            >
                              Fiyata Göre
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="right-column pull-right clearfix">
                        <div className="select-box">
                          <select
                            className="wide nice-select"
                            onChange={(e) =>
                              props.setAscDesc &&
                              props.setAscDesc(e.target.value === "1")
                            }
                          >
                            <option data-display="Sıralama">
                              Artan/Azalan
                            </option>
                            <option value="1">Artan</option>
                            <option value="0">Azalan</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="wrapper grid">
                {!groupedProducts || groupedProducts.length === 0 ? (
                  <div className="shop-grid-content">
                    <div className="clearfix">
                      <h1>Aradığınız kriterde ürün bulunamadı...</h1>
                    </div>
                  </div>
                ) : (
                  groupedProducts.map((group) => (
                    <div className="shop-grid-content">
                      <div className="shop-grid-row clearfix">
                        {group?.map?.((product) => (
                          <ProductBlock
                            key={product.id}
                            imageUrl={product.imageUrls![0]}
                            productName={
                              product.brand.name + " " + product.name
                            }
                            description={product.excerpt}
                            productUrl={`/product/${product.url}`}
                            rating={product.rating!}
                            price={product.price}
                            discountedPrice={product.compareAtPrice || 0}
                            reviewCount={product.reviews!}
                            product={product}
                            addCart={props.addCart}
                            vehicle={lastSelectedVehicle?.vehicle}
                          />
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
              {props.pagingActive &&
              !!groupedProducts &&
              groupedProducts.length !== 0 ? (
                <div className="pagination-wrapper centred mt_40">
                  <ul className="pagination clearfix">
                    {
                      // pageSize kadar sayfa yönlendirme butonu oluşturulacak
                      productListResponse.totalPages &&
                        Array.from(
                          { length: productListResponse.totalPages },
                          (_, index) => (
                            <>
                            {
                              ((productListResponse.currentPage <= index + 2 &&
                                productListResponse.currentPage >= index - 2) ||
                              index === productListResponse.totalPages - 1 ||
                              index === 0) &&
                            <li key={index}>
                              <button
                                onClick={() => {
                                  setPage(index + 1);
                                  props.setPage && props.setPage(index + 1);
                                  window.scrollTo({ top: 0, behavior: "smooth" });
                                }}
                                className={
                                  productListResponse.currentPage === index + 1
                                    ? "current"
                                    : ""
                                }
                              >
                                {index + 1}
                              </button>
                            </li>}
                            </>
                          ),
                        )
                    }
                    {productListResponse.totalPages > 1 && (
                      <li>
                        <button
                          onClick={() => {
                            if (productListResponse.totalPages === page) {
                              return;
                            }
                            setPage(page + 1);
                            props.onNextPage && props.onNextPage();
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          <i className="fas fa-arrow-right"></i>
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
