import React, { useEffect } from "react";
import { IAddress, IAddressData } from "../../../interfaces/address";
import { useSelector } from "react-redux";
import {
  addAddressAsync,
  fetchAddressListAsync,
  removeAddressAsync,
  selectAddressList,
  selectAddressStatus,
  updateAddressAsync,
} from "../../../redux/slices/addressSlice";
import Modal from "../../../component/modal/Modal";
import AddAddress from "../../../component/addAddress/AddAddress";
import { useAppDispatch } from "../../../redux/hooks";
import { divideArrayIntoGroups } from "../../../component/weekDeal/ProductUtil";
import { toast } from "react-toastify";

interface IAddressProps {}

export default function Addresses(props: IAddressProps) {
  const dispatch = useAppDispatch();
  const addressList = useSelector(selectAddressList);
  const [showModal, setShowModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [editAddress, setEditAddress] = React.useState<IAddress>(
    {} as IAddress,
  );
  const [address, setAddress] = React.useState<IAddress>({} as IAddress);
  useEffect(() => {
    dispatch(fetchAddressListAsync());
  }, []);
  const addressStatus = useSelector(selectAddressStatus);

  const groupedAddressList = divideArrayIntoGroups(addressList, 2);

  return (
    <div className="card">
      <div className="card-header">
        <h5>Adreslerim</h5>
      </div>
      <div className="container pt-3">
        {addressStatus === "loading" ? (
          <p>Yükleniyor...</p>
        ) : (
          groupedAddressList.map((group, index) => (
            <div className="row" key={index}>
              {group.map((address) => (
                <div
                  className="col-lg-6 col-md-6 col-sm-12 mb-3"
                  key={address.id}
                >
                  <div className="card p-3 h-100">
                    <h5>{address.addressName + address.addressType}</h5>
                    <p>
                      {address.name} {address.surname}
                    </p>
                    <p>{address.addressText}</p>
                    <p>{address.phone}</p>
                    <p>{address.email}</p>
                    <div>
                      {address.isInvoice && (
                        <div>
                          <p>{address.invoiceTitle}</p>
                          <p>{address.taxOffice}</p>
                          <p>{address.taxIdentificationNumber}</p>
                        </div>
                      )}
                    </div>
                    <div className="mt-auto">
                      <button
                        className="btn btn-secondary mr-1"
                        onClick={() => {
                          setEditAddress(address);
                          setShowEditModal(true);
                        }}
                      >
                        Düzenle
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          dispatch(removeAddressAsync(address.id)).then(() => {
                            dispatch(fetchAddressListAsync());
                          });
                        }}
                      >
                        Sil
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
        <div className="m-3 col-sm-12 mt-3">
          <button
            className="btn"
            type="submit"
            onClick={() => setShowModal(true)}
          >
            Adres Ekle
          </button>
        </div>

        <Modal
          className="modal-dialog-scrollable"
          sizeClass="modal-lg"
          modalTitle="Adres Ekle"
          show={showModal}
          setShowModal={(show) => {
            setShowModal(show);
            setShowError(false);
          }}
          saveAndClose={() => {
            if (
              !address.name ||
              !address.surname ||
              !address.addressText ||
              !address.cityId ||
              !address.districtId ||
              !address.neighborhoodId ||
              !address.email ||
              !address.phone ||
              !address.addressName ||
              (address.invoiceType === 1 &&
                (!address.invoiceTitle ||
                  !address.taxOffice ||
                  !address.taxIdentificationNumber))
            ) {
              setShowError(true);
              return;
            }
            dispatch(
              addAddressAsync({
                addressName: address.addressName,
                name: address.name,
                surname: address.surname,
                addressText: address.addressText,
                addressType: address.addressType,
                cityId: address.cityId,
                cityName: address.cityName,
                districtId: address.districtId,
                districtName: address.districtName,
                neighborhoodId: address.neighborhoodId,
                neighborhoodName: address.neighborhoodName,
                email: address.email,
                phone: address.phone,
                isInvoice: address.isInvoice || false,
                invoiceType: address.invoiceType || 0,
                invoiceTitle: address.invoiceTitle || "",
                taxOffice: address.taxOffice || "",
                taxIdentificationNumber: address.taxIdentificationNumber || "",
                postCode: address.postCode || "",
                default: address.default,
              }),
            ).then(() => {
              setShowModal(false);
              setShowError(false);
              dispatch(fetchAddressListAsync());
            }).catch(() => {
              toast("Adres eklenirken bir hata oluştu.", {type: "error"});
            })
          }}
        >
          <AddAddress
            address={address}
            setAddress={setAddress}
            showError={showError}
          />
        </Modal>
        <Modal
          className="modal-dialog-scrollable"
          sizeClass="modal-lg"
          modalTitle="Adres Düzenle"
          show={showEditModal}
          setShowModal={(show) => {
            setShowEditModal(show);
            setShowError(false);
          }}
          saveAndClose={() => {
            if (
              editAddress.id === undefined ||
              !editAddress.name ||
              !editAddress.surname ||
              !editAddress.addressText ||
              !editAddress.cityId ||
              !editAddress.districtId ||
              !editAddress.neighborhoodId ||
              !editAddress.email ||
              !editAddress.phone ||
              !editAddress.addressName ||
              (editAddress.invoiceType === 1 &&
                (!editAddress.invoiceTitle ||
                  !editAddress.taxOffice ||
                  !editAddress.taxIdentificationNumber))
            ) {
              setShowError(true);
              return;
            }
            dispatch(
              updateAddressAsync({
                id: editAddress.id,
                addressName: editAddress.addressName,
                name: editAddress.name,
                surname: editAddress.surname,
                addressText: editAddress.addressText,
                addressType: editAddress.addressType,
                cityId: editAddress.cityId,
                cityName: editAddress.cityName,
                districtId: editAddress.districtId,
                districtName: editAddress.districtName,
                neighborhoodId: editAddress.neighborhoodId,
                neighborhoodName: editAddress.neighborhoodName,
                email: editAddress.email,
                phone: editAddress.phone,
                isInvoice: editAddress.isInvoice || false,
                invoiceType: editAddress.invoiceType || 0,
                invoiceTitle: editAddress.invoiceTitle || "",
                taxOffice: editAddress.taxOffice || "",
                taxIdentificationNumber:
                  editAddress.taxIdentificationNumber || "",
                postCode: editAddress.postCode || "",
                default: editAddress.default,
              }),
            ).then(() => {
              setShowEditModal(false);
              setShowError(false);
              dispatch(fetchAddressListAsync());
            });
          }}
        >
          <AddAddress
            address={editAddress}
            setAddress={setEditAddress}
            showError={showError}
          />
        </Modal>
      </div>
    </div>
  );
}
