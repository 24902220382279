import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  addCartAsync,
  addItemInCartNonAuth,
  selectCart,
  updateCartVehicleSelector,
} from "../../redux/slices/cartSlice";
import Modal from "../modal/Modal";
import cartSlice from "../../redux/slices/cartSlice";
import { IVehicle, IMyVehicle } from "../../interfaces/garage";
import { useAppDispatch } from "../../redux/hooks";
import {
  selectSelectedVehicle,
  selectSelectedMyVehicle,
  clearSelectedMyVehicle,
  clearSelectedVehicle,
  setSelectedMyVehicle,
  setSelectedVehicle,
  selectVehicles,
} from "../../redux/slices/garageSlice";
import MyVehicleSelect from "../../pages/maintainRobot/MyVehicleSelect";
import BannerVehicleSelect from "../banner/BannerVehicleSelect";
import SelectedVehicle from "../selectedVehicle/SelectedVehicle";
import { selectUser } from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import { ICartItem } from "../../interfaces/cart";
import { getCompatibleVehicles } from "../../redux/api/vehicleApi";

function CartVehicleSelector() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser) as any;
  const isUserAuth = !!user?.user;
  const vehicles = useSelector(selectVehicles);
  const selectedVehicle = useSelector(selectSelectedVehicle);
  const selectedMyVehicle = useSelector(selectSelectedMyVehicle);
  const [isVehicleSupportError, setIsVehicleSupportError] =
    React.useState(false);

  const { cartVehicleSelector } = useSelector(selectCart);

  const lastSelectedVehicle = useMemo(
    () =>
      selectedVehicle
        ? ({ vehicle: selectedVehicle as IVehicle } as IMyVehicle)
        : selectedMyVehicle?.vehicle?.id
          ? selectedMyVehicle
          : undefined,
    [selectedVehicle, selectedMyVehicle],
  );

  const setVehicle = (vehicle: IVehicle) => {
    dispatch(setSelectedVehicle(vehicle));
    dispatch(clearSelectedMyVehicle());
  };
  const setMyVehicle = (vehicle: IMyVehicle) => {
    dispatch(setSelectedMyVehicle(vehicle));
    dispatch(clearSelectedVehicle());
  };

  const onVehicleClose = () => {
    dispatch(clearSelectedVehicle());
    dispatch(clearSelectedMyVehicle());
  };

  const onModalClose = (show: boolean) => {
    dispatch(
      updateCartVehicleSelector({
        isVehicleSelectorOpen: show,
      }),
    );
  };

  const modalSaveAndClose = async () => {
    setIsVehicleSupportError(false);
    if (!lastSelectedVehicle?.vehicle) {
      toast.error("Lütfen bir araç seçiniz.");
      return;
    }
    if (!cartVehicleSelector.cartItem?.product.id) {
      toast.error("Ürün seçimi yapılmadığı için ürün sepete eklenemedi.");
      return;
    }
    const vehicleList = await getCompatibleVehicles(
      cartVehicleSelector.cartItem?.product.id,
    );
    if (
      vehicleList.length === 0 ||
      vehicleList.findIndex(
        (v: IVehicle) => v.id === lastSelectedVehicle?.vehicle?.id,
      ) === -1
    ) {
      setIsVehicleSupportError(true);
      return;
    }
    onModalClose(false);
    if (!isUserAuth) {
      dispatch(
        addItemInCartNonAuth({
          ...cartVehicleSelector.cartItem,
          vehicle: lastSelectedVehicle.vehicle,
        } as ICartItem),
      );
    } else {
      dispatch(
        addCartAsync({
          ...cartVehicleSelector.cartItem,
          vehicle: lastSelectedVehicle.vehicle,
        } as ICartItem),
      );
    }
  };

  return (
    <div>
      <Modal
        modalTitle="Sepete ürün eklemek için araç seçiniz"
        show={!!cartVehicleSelector?.isVehicleSelectorOpen}
        setShowModal={onModalClose}
        saveAndClose={modalSaveAndClose}
      >
        <>
          {!(lastSelectedVehicle && lastSelectedVehicle?.vehicle?.id) && (
            <>
              <div className="mb-3">
                {isUserAuth && (
                  <>
                    <h5>Garajınızdan araç seçiniz</h5>
                    <MyVehicleSelect
                      vehicleList={vehicles}
                      selectedVehicle={selectedMyVehicle}
                      setSelectedVehicle={setMyVehicle}
                    />
                  </>
                )}
              </div>
              <div className="mb-3">
                <h5>Araç seçiniz</h5>
                <BannerVehicleSelect
                  vehicle={selectedVehicle}
                  onSubmit={(vehicle, year) => {
                    vehicle.year = year;
                    setVehicle(vehicle);
                  }}
                />
              </div>
            </>
          )}
          {lastSelectedVehicle && lastSelectedVehicle?.vehicle?.id && (
            <div className="mb-3">
              <SelectedVehicle
                myVehicle={lastSelectedVehicle}
                onClose={onVehicleClose}
              />
            </div>
          )}
          {isVehicleSupportError && (
            <div className="alert alert-danger">
              Bu araç ile ürün uyumlu değil.
            </div>
          )}
        </>
      </Modal>
    </div>
  );
}
export default CartVehicleSelector;
