import React, { useEffect, useMemo, useState } from "react";
import ProductDetail from "../../component/productDetail/ProductDetail";
import { useParams } from "react-router-dom";
import { fetchProductsById, fetchProductsBySku } from "../../redux/api/productApi";
import { useAppDispatch } from "../../redux/hooks";
import { addCartAsync } from "../../redux/slices/cartSlice";
import { IVehicle, IMyVehicle } from "../../interfaces/garage";
import { selectSelectedVehicle, selectSelectedMyVehicle } from "../../redux/slices/garageSlice";
import { toast } from "react-toastify";

export default function Product() {
  const { productId } = useParams();
  const productIdParam = productId?.split("-p-")[1];
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    // fetch product by id
    if (!productIdParam) {
      return;
    }
    fetchProductsBySku(productIdParam)
      .then((response) => {
        if (!response) {
          setLoading(false);
          return;
        }
        setProduct(response);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productIdParam]);

  return (
    <div>
      {loading && <div>Yükleniyor...</div>}
      {error && <div>Hata oluştu</div>}
      {product && (
        <ProductDetail
          product={product}
          addToCart={(product) => {
            dispatch(
            addCartAsync({
              product: product,
              options: [],
              price: product.price,
              quantity: 1,
              total: product.price,
            }),
          );
        }}
        />
      )}
    </div>
  );
}
