export interface City {
  id: number;
  name: string;
  // Diğer City özellikleri buraya eklenebilir
}

export interface District {
  id: number;
  name: string;
  // Diğer District özellikleri buraya eklenebilir
}
export interface IAddressData {
  addressName: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  addressType: number;
  isInvoice: boolean;
  invoiceType: number;
  invoiceTitle: string;
  taxOffice: string;
  taxIdentificationNumber: string;
  addressText: string;
  cityId: number;
  cityName: string;
  districtId: number;
  districtName: string;
  neighborhoodId: number;
  neighborhoodName: string;
  postCode: string;
  default: boolean;
}

export interface IAddress extends IAddressData {
  id: number;
  district?: District;
  city?: City;
}

export function formatAddress(address:IAddress) {
  if (!address) return '';
  return `${address.name}, ${address.addressText}, ${address.districtName}, ${address.cityName}, Türkiye, Tel: ${address.phone}, Email: ${address.email}`;
}
